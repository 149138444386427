<template>
  <div>
    <div class="mb-10">
      <h1 title="Login" class="mb-0">OTP Verification</h1>
      <p class="muted--text">Please enter the OTP sent to your email</p>

      <v-alert v-if="undefined != err.message" type="error" class="mb-8" outlined dense>{{
        err.message
      }}</v-alert>
      <v-alert v-if="message" type="success" class="mb-8" outlined dense>{{
        message
      }}</v-alert>
    </div>
    <form @submit.prevent="submitOtpCode">
      <div class="mb-10 digits">
        <v-text-field 
        type="text" 
        outlined
        hide-details
        class="dt-text-field centered-text"
        required
        v-for="(digit, index) in otpDigits" 
        :key="index" 
        v-model="otpDigits[index]" 
        :ref="`digit${index}`"
        maxlength="1" 
        @input="handleInput(index)"
        @keydown="handleBackspace(index, $event)"
        @paste="handlePaste($event, index)"></v-text-field>
      </div>
      <div class="text-center mb-3">
        <v-btn block x-large color="primary" :disabled="loading" exact type="submit" ref="submitBtn">
          <span v-text="loading ? 'Verifying' : 'Confirm'"></span>
        </v-btn>
      </div>
      <div class="text-center">
        <small> Didn't receive OTP Code? <a href="#resendOtp" @click="resendOtp">Resend OTP <span v-if="remainingTime > 0">({{ remainingTime  }})</span></a> </small>
      </div>
    </form>
  </div>
</template>

<script>
import hondaLogo from "@/assets/honda_logo.png";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      hondaLogo,
      password: null,
      otpDigits: ['', '', '', '', '', ''],
      message: "",
      email: this.$route.params.email,
      seconds: 0,
      remainingTime: 0,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      err: "auth/GET_ERR",
      user: "auth/GET_USER",
    })
  },
  mounted(){
    if(!this.email){
      this.$router.push({
        name: "auth.login"
      })
    }
  },
  methods: {
    ...mapActions({
      otpSubmit: "auth/actionSubmitOtp",
      otpResend: "auth/actionResendOtp",
    }),

    handleInput(index) {
      try {
        // Ensure input contains only digits
        var input = this.otpDigits[index].replace(/\D/g, '');
        this.otpDigits[index] = input;
        if(!input){
          this.$refs[`digit${index}`][0].reset();
        }
        
        // Move to next input field automatically
        if (this.otpDigits[index].length === 1 && index < this.otpDigits.length - 1) {
          this.$refs[`digit${index + 1}`][0].focus();
        }
        
      } catch (error) {
        // 
      }
    },
    handleBackspace(index, event) {
      if (event.key === 'Backspace' && index > 0 && (!this.otpDigits[index])) {
        // Move focus to the previous input field
        this.$refs[`digit${index - 1}`][0].focus();
      }
    },
    handlePaste(event, index) {
      event.preventDefault();
      const pastedData = event.clipboardData.getData('text');
      const digits = pastedData.split('').filter(digit => !isNaN(parseInt(digit, 10))).slice(0, 6);

      if(digits.length == 6) {
        var temp = []
        digits.forEach((digit, i) => {
          temp.push(digit)
          if (index + i < this.otpDigits.length - 1) {
            this.$refs[`digit${index + i + 1}`][0].focus();
          }
        });
        
        this.otpDigits = temp;
      }
    },
    async submitOtpCode() {
      if(this.loading){
        return
      }

      this.loading = true
      var code = this.otpDigits.join('');
      
      if(!code || code.length < 6) {
        this.loading = false
        return;
      }

      const res = await this.otpSubmit({
        code: code,
        email: this.email
      })

      this.loading = false

      if (!this.err.message) {
        this.message = "OTP Verified! Redirecting to dashboard"
        this.$router.push({
          name: "home",
        });
      }

      if (res.error_code == "ACCOUNT_LOCKED") {
        this.$router.push({
          name: "auth.login",
        });
      }

    },
    async resendOtp() {
      if(this.remainingTime > 0) {
        return
      }

      const res = await this.otpResend({
        email: this.email
      })

      if (!this.err.message) {
        var interval = parseInt(res.interval)
        this.seconds = interval
        this.remainingTime = interval
        this.startCountdown()

        this.otpDigits = ['', '', '', '', '', '']
      }

      if (res.error_code) {
        this.$router.push({
          name: "auth.login",
        });
      }
    },
    startCountdown() {
      // set seconds and remainingTime using int from api res
      const targetTime = Date.now() + this.seconds * 1000;
      const interval = setInterval(() => {
        this.remainingTime = Math.round((targetTime - Date.now()) / 1000);
        if (this.remainingTime <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
.btnLogin {
  background-color: #00aad8 !important;
  color: white !important;
}

.bg-white {
  background: #fff !important;
}

.digits {
  display: flex;
  gap: 5px;
}

.centered-text :deep(input) {
  text-align: center !important;
}

.centered-text .v-text-field input {
  text-align: center !important;
}
</style>
